import { useEffect, createContext, useContext, useMemo, useCallback } from 'react';
import { ethers } from 'ethers';
import { ConnectOptions } from '@web3-onboard/core';
import { init, useConnectWallet, useWallets } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import gnosisModule from '@web3-onboard/gnosis';
import walletConnectModule from '@web3-onboard/walletconnect';
import { Buffer } from 'buffer';

import { supportedNetworks, targetChainId, Network } from '../data/chain';
import icon from '../assets/vectors/icon.svg';

window.Buffer = Buffer;

interface Web3ContextType {
  connected: boolean;
  connect: () => void;
  disconnect?: () => void;
  chainConfig: Network;
  provider?: ethers.providers.JsonRpcProvider;
  signer?: ethers.providers.JsonRpcSigner;
  address?: string;
  networkError?: Error;
}

const injected = injectedModule();
const walletConnect = walletConnectModule({
  projectId: process.env.REACT_APP_WALLET_CONNECT_ID,
});
const gnosis = gnosisModule();

init({
  wallets: [injected, walletConnect, gnosis],
  chains: Object.values(supportedNetworks).map(
    ({ chainId, chainName, nativeCurrency, rpcUrls }) => ({
      id: chainId,
      token: nativeCurrency.symbol,
      label: chainName,
      rpcUrl: rpcUrls[0],
    }),
  ),
  appMetadata: {
    name: 'Increment',
    description:
      'Increment is a decentralized, algorithmic perpetual futures protocol building on zkSync 2.0, empowering on-chain global exchange rate and crypto derivatives trading.',
    icon,
  },
  connect: {
    showSidebar: false,
  },
  accountCenter: {
    desktop: {
      enabled: false,
    },
    mobile: {
      enabled: false,
    },
  },
});

const isValidNetwork = (networkId: number): boolean =>
  supportedNetworks[networkId.toString()] !== undefined;

const Web3Context = createContext<Web3ContextType>({
  connected: false,
  connect: () => null,
  chainConfig: supportedNetworks[+targetChainId],
});

export function Web3Provider({ children }: { children: React.ReactNode }) {
  const [{ wallet }, onboardConnect, onboardDisconnect] = useConnectWallet();
  const wallets = useWallets();

  const connect = useCallback(async () => {
    if (!wallet) {
      onboardConnect();
    }
  }, [wallet, onboardConnect]);

  const disconnect = useCallback(() => {
    if (wallet) {
      onboardDisconnect(wallet);
    }
  }, [wallet, onboardDisconnect]);

  const defaultProvider = useMemo(() => {
    const targetNetwork = supportedNetworks[+targetChainId];
    return new ethers.providers.JsonRpcProvider(targetNetwork.rpcUrls[0], {
      name: targetNetwork.chainName,
      chainId: +targetNetwork.chainId,
    });
  }, []);

  const { walletProvider, signer, address, chainConfig, networkError } = useMemo(() => {
    if (wallet) {
      const ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any');
      const chainId = wallet.chains[0]?.id;
      const validNetwork = isValidNetwork(+chainId);
      return {
        walletProvider: ethersProvider,
        signer: ethersProvider.getSigner(),
        address: wallet.accounts[0]?.address,
        chainConfig:
          validNetwork || !supportedNetworks[chainId]
            ? supportedNetworks[+targetChainId]
            : supportedNetworks[chainId],
        networkError: validNetwork ? undefined : new Error('Wrong Network'),
      };
    }
    return {
      chainConfig: supportedNetworks[+targetChainId],
    };
  }, [wallet]);

  const value = useMemo(
    () => ({
      connected: wallet !== null,
      connect,
      disconnect,
      provider: networkError ? defaultProvider : walletProvider,
      signer,
      address,
      networkError,
      chainConfig,
    }),
    [
      wallet,
      connect,
      disconnect,
      walletProvider,
      defaultProvider,
      signer,
      address,
      networkError,
      chainConfig,
    ],
  );

  useEffect(() => {
    let options: ConnectOptions | undefined;
    try {
      const previousWallets = JSON.parse(window.localStorage.getItem('connectedWallets') ?? '{}');
      options = previousWallets?.[0]
        ? {
            autoSelect: {
              label: previousWallets[0],
              disableModals: true,
            },
          }
        : undefined;
    } catch (err) {
      console.error(err);
    }
    onboardConnect(options);
  }, [onboardConnect]);

  useEffect(() => {
    const connectedWallets = wallets?.map(({ label }) => label);
    if (connectedWallets) {
      window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets));
    }
  }, [wallets]);

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
}

const useWeb3 = () => useContext(Web3Context) as Web3ContextType;

export default useWeb3;
