export const ONE_DAY = 86400;
export const ONE_HOUR = 3600;

export const intervalMap: { [key: string]: number } = {
  '1d': 86400,
  '1w': 604800,
  '1m': 2629743,
};

export type ChartInterval = '1d' | '1w' | '1m';

export type FillType = 'last' | 'zero';

export type AggregateType = 'sum' | 'latest';

export interface DataPoint {
  time: string;
  value: number;
}
