import { ReactNode, useCallback } from 'react';
import styled from 'styled-components/macro';

import { ReactComponent as Exit } from '../../assets/vectors/exit.svg';
import { H5 } from '../Text';

const ModalBackground = styled.div<{ isOpen: boolean }>`
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.bg2};
  border-radius: 0.675rem;
  overflow: hidden;
  width: auto;
  max-width: 90%;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.bg3};
  padding: 0.75rem 1.75rem;
`;

const ExitIcon = styled(Exit)`
  &:hover {
    cursor: pointer;
  }
`;

interface ModalProps {
  isOpen: boolean;
  title: string;
  content: ReactNode;
  closeModal: () => void;
}

export function Modal({ isOpen, title, content, closeModal }: ModalProps) {
  const closeModalWrapper = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <ModalBackground isOpen={isOpen} onClick={closeModalWrapper}>
      <ModalContainer
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ModalHeader>
          <H5>{title}</H5>
          <ExitIcon onClick={closeModalWrapper} />
        </ModalHeader>
        {content}
      </ModalContainer>
    </ModalBackground>
  );
}
