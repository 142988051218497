import { BigNumber, providers } from 'ethers';
import { formatEther, formatUnits, parseEther } from 'ethers/lib/utils';

import { KeyMap, Side } from './types';
import {
  AggregateType,
  ChartInterval,
  DataPoint,
  FillType,
  intervalMap,
} from '../components/ReChart/types';
import { calcLiquidationPrice } from './helpers';
import { Market } from '../contexts/MarketsContext';
import { getTraderFundingPayments } from './clearingHouse';
import { Network } from '../data/chain';

interface EntityWithMarket {
  market: {
    id: string;
  };
}

export interface Position {
  id: string;
  market: {
    id: string;
    symbol: string;
    name: string;
    cryptoSwapPool: string;
    latestPrice: {
      value: string;
    };
  };
  timestamp: string;
  closeTimestamp?: string;
  direction: Side;
  initialCumFundingRate: string;
  recentCumFundingRate?: string;
  openNotional: string;
  positionSize: string;
  entryPrice: string;
}

export interface LpPosition {
  id: string;
  market: {
    id: string;
    symbol: string;
    name: string;
    cryptoSwapPool: string;
    latestPrice: {
      value: string;
    };
  };
  timestamp: string;
  closeTimestamp?: string;
  openNotional: string;
  positionSize: string;
  initialCumFundingPerLpToken: string;
  recentCumFundingPerLpToken?: string;
  liquidityBalance: string;
  totalTradingFeesGrowth: string;
  totalQuoteFeesGrowth: string;
  totalBaseFeesGrowth: string;
}

export interface TokenBalance {
  id: string;
  token: {
    id: string;
    decimals: number;
    address: string;
    prices: {
      answer: string;
      decimals: string;
    }[];
  };
  amount: string;
  timestamp: string;
}

export interface GlobalPosition {
  market: {
    id: string;
  };
  timestamp: string;
  totalTradingFeesGrowth: string;
  totalLiquidityProvided: string;
  totalBaseFeesGrowth: string;
  totalQuoteFeesGrowth: string;
}

export interface GlobalPositionAggregate {
  market: {
    id: string;
  };
  openTimestamp: string;
  closeTimestamp: string;
  totalTradingFeesGrowth: string;
  totalLiquidityProvided: string;
  totalBaseFeesGrowth: string;
  totalQuoteFeesGrowth: string;
}

export interface MarketPrice {
  market: {
    id: string;
  };
  timestamp: string;
  index: string;
  cumFundingRate: string;
  quoteSupply: string;
  baseSupply: string;
}

export interface TokenPrice {
  token: {
    id: string;
  };
  timestamp: string;
  decimals: string;
  answer: string;
}

interface HydratePositionDataParams {
  position: Position;
  reserveValue: BigNumber;
  minMargin: BigNumber;
  marketList: Market[];
  chainConfig: Network;
  provider: providers.Provider;
  userAddress: string;
}

export interface HydratedPosition {
  id: string;
  marketId: string;
  ref: Position;
  name: string;
  flag: string;
  direction: string;
  size: string;
  entryPrice: string;
  lastPrice: string;
  closed: boolean;
  liquidationPrice?: string;
  unrealizedPnl?: BigNumber;
  fundingPayments?: BigNumber;
}

interface CalcPortfolioValueParams {
  globalPositions: KeyMap<GlobalPosition>;
  marketPrices: KeyMap<MarketPrice>;
  tokenPrices: KeyMap<TokenPrice>;
  positions: Position[];
  lpPositions: LpPosition[];
  tokenBalances: TokenBalance[];
}

export const hydratePositionData = async ({
  position,
  reserveValue,
  minMargin,
  marketList,
  chainConfig,
  provider,
  userAddress,
}: HydratePositionDataParams): Promise<HydratedPosition | null> => {
  try {
    // Get Market display name, img
    const marketInfo = marketList.find((market) => market.id === position.market.id);
    if (!marketInfo || Number(position.positionSize) === 0) {
      return null;
    }

    const openNotional = Math.abs(Number(formatEther(position.openNotional)));
    const positionSize = Math.abs(Number(formatEther(position.positionSize)));

    const resultPosition = {
      id: position.id,
      marketId: marketInfo.id,
      ref: position,
      name: marketInfo.symbol,
      flag: marketInfo.img,
      direction: position.direction === Side.Long ? 'Long' : 'Short',
      size: Math.abs(openNotional).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
      entryPrice: Number(formatEther(position.entryPrice)).toFixed(marketInfo.displayDecimals),
      lastPrice: Number(formatEther(position.market.latestPrice.value)).toFixed(
        marketInfo.displayDecimals,
      ),
      closed: !!position.closeTimestamp || positionSize === 0,
    };

    if (resultPosition.closed) {
      return resultPosition;
    }

    const fundingPayments = await getTraderFundingPayments({
      marketId: marketInfo.id,
      signerOrProvider: provider,
      chainConfig,
      userAddress,
    });

    let liquidationPrice;
    try {
      liquidationPrice = calcLiquidationPrice(
        minMargin,
        reserveValue,
        fundingPayments,
        BigNumber.from(position.openNotional),
        BigNumber.from(position.positionSize),
      );
    } catch (e) {
      console.warn(e);
      liquidationPrice = BigNumber.from(-1);
    }

    const vQuoteProceeds = BigNumber.from(position.positionSize).mulWei(marketInfo.price);
    const unrealizedPnl = vQuoteProceeds.add(position.openNotional);

    return {
      ...resultPosition,
      fundingPayments,
      liquidationPrice: liquidationPrice.lt(0)
        ? undefined
        : Number(formatEther(liquidationPrice)).toFixed(marketInfo.displayDecimals),
      unrealizedPnl,
    };
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const filterChartData = (
  dataPoints: DataPoint[],
  interval: ChartInterval,
  fillType: FillType,
  aggregateType: AggregateType,
  granularity: number,
): DataPoint[] => {
  if (dataPoints.length <= 0) {
    return [];
  }

  const filteredData: DataPoint[] = [];
  const intervalVal = intervalMap[interval];
  const now = Date.now() * 10 ** -3; // adjust to seconds
  const earliestTimestamp = intervalVal ? now - intervalVal : Number(dataPoints[0].time);

  let lastPointSet: DataPoint;
  dataPoints.forEach((dataPoint) => {
    if (+dataPoint.time > +earliestTimestamp) {
      // set day as latest value or sum
      const period = Math.floor((+dataPoint.time - +earliestTimestamp) / granularity);

      // Set or update value
      if (aggregateType === 'sum' && filteredData[period] !== undefined) {
        filteredData[period].value += dataPoint.value;
      } else {
        filteredData[period] = dataPoint;
      }

      // fill in missing days
      // Grab timestamp of the last point set
      const lastPeriodSet = lastPointSet
        ? Math.floor((+lastPointSet.time - +earliestTimestamp) / granularity)
        : undefined;

      // if there is a gap in the data then set the missing values to the last value available or 0
      if (period !== 0 && (!lastPeriodSet || period - lastPeriodSet > 1)) {
        for (let i = lastPeriodSet ? lastPeriodSet + 1 : 0; i < period; i += 1) {
          filteredData[i] = {
            value: fillType === 'last' ? lastPointSet?.value ?? 0 : 0,
            // current period - 24h * gap
            time: `${Number(dataPoint.time) - (period - i) * granularity}`,
          };
        }
      }
    }

    lastPointSet = dataPoint;
  });

  // if there is no data point in the selected interval
  if (filteredData.length === 0) {
    if (fillType === 'last') {
      filteredData.push(dataPoints[dataPoints.length - 1]);
    } else {
      filteredData.push({
        time: earliestTimestamp.toString(),
        value: 0,
      });
    }
  }

  // fill in missing values between last action and current day
  const lastDataPoint = filteredData[filteredData.length - 1];
  if (lastDataPoint) {
    const lastTimestamp = Number(lastDataPoint.time);
    const missingDays = (now - lastTimestamp) / granularity;
    for (let i = 1; i <= missingDays; i += 1) {
      filteredData.push({
        value: fillType === 'last' ? lastDataPoint.value : 0,
        time: `${lastTimestamp + i * granularity}`,
      });
    }
  }

  return filteredData;
};

export const calcPortfolioValue = ({
  globalPositions,
  marketPrices,
  tokenPrices,
  positions,
  lpPositions,
  tokenBalances,
}: CalcPortfolioValueParams): BigNumber => {
  let portfolioValue = BigNumber.from(0);

  // Calc value of each trading position
  positions.forEach((position) => {
    const marketId = position.market.id;
    if (globalPositions[marketId] !== undefined && marketPrices[marketId] !== undefined) {
      // indexPrice * positionSize + openNotional
      const pnl = BigNumber.from(position.positionSize)
        .mulWei(marketPrices[marketId].index)
        .add(position.openNotional);
      // (globalCumFundingRate - positionCumFundingRate) * positionSize
      const upcomingFundingRate = BigNumber.from(position.positionSize).gt(0)
        ? BigNumber.from(position.recentCumFundingRate ?? position.initialCumFundingRate).sub(
            marketPrices[marketId].cumFundingRate,
          )
        : BigNumber.from(marketPrices[marketId].cumFundingRate).sub(
            position.recentCumFundingRate ?? position.initialCumFundingRate,
          );
      const fundingPayments = upcomingFundingRate.mulWei(
        BigNumber.from(position.positionSize).abs(),
      );
      // portfolio value += (pnl + fundingPayments)
      portfolioValue = portfolioValue.add(pnl.add(fundingPayments));
    }
  });

  // Calc value of each lp position
  lpPositions.forEach((lpPosition) => {
    const marketId = lpPosition.market.id;
    if (globalPositions[marketId] !== undefined && marketPrices[marketId] !== undefined) {
      // positionLpBalance / totalLiquidityProvided
      const lpShare = BigNumber.from(lpPosition.liquidityBalance).divWei(
        globalPositions[marketId].totalLiquidityProvided,
      );
      // lpShare * totalBaseSupply / (1 + globalBaseFeesGrowth - positionBaseFeesGrowth)
      const withdrawableBase = lpShare
        .mulWei(marketPrices[marketId].baseSupply)
        .divWei(
          parseEther('1')
            .add(globalPositions[marketId].totalBaseFeesGrowth)
            .sub(lpPosition.totalBaseFeesGrowth),
        );
      // lpShare * totalQuoteSupply / (1 + globalQuoteFeesGrowth - positionQuoteFeesGrowth)
      const withdrawableQuote = lpShare
        .mulWei(marketPrices[marketId].quoteSupply)
        .divWei(
          parseEther('1')
            .add(globalPositions[marketId].totalQuoteFeesGrowth)
            .sub(lpPosition.totalQuoteFeesGrowth),
        );
      // withdrawableBase + positionSize
      const activePositionSize = withdrawableBase.add(lpPosition.positionSize);
      // withdrawableQuote + openNotional
      const activeOpenNotional = withdrawableQuote.add(lpPosition.openNotional);
      // activePositionSize * indexPrice + activeOpenNotional
      const pnl = activePositionSize.mulWei(marketPrices[marketId].index).add(activeOpenNotional);
      // activePositionSize * (globalCumFundingRate - lpPositionCumFundingRate)
      // TODO: Update to use Global cumFundingPerLpToken
      // const fundingPayments = BigNumber.from(lpPosition.liquidityBalance).mulWei(
      //   BigNumber.from(marketPrices[marketId].cumFundingRate).sub(
      //     lpPosition.recentCumFundingPerLpToken ?? lpPosition.initialCumFundingPerLpToken,
      //   ),
      // );
      const fundingPayments = BigNumber.from(0);
      // abs(openNotional) * (globalTradingFeesGrowth - lpTradingFeesGrowth)
      const tradingFeesEarned = BigNumber.from(lpPosition.openNotional)
        .abs()
        .mulWei(
          BigNumber.from(globalPositions[marketId].totalTradingFeesGrowth).sub(
            lpPosition.totalTradingFeesGrowth,
          ),
        );
      // portfolioValue += (pnl + fundingPayments + tradingFeesEarned)
      portfolioValue = portfolioValue.add(pnl.add(fundingPayments).add(tradingFeesEarned));
    }
  });

  // Calc value of collateral deposits
  tokenBalances.forEach((tokenBalance) => {
    const tokenPrice = tokenPrices[tokenBalance.token.id];
    // Adjust token price to 10e18
    const tokenPriceWei = parseEther(formatUnits(tokenPrice.answer, tokenPrice.decimals));
    // tokenBalance * tokenPrice
    const balanceValue = tokenPriceWei.mulWei(tokenBalance.amount);
    // portfolio += balanceValue
    portfolioValue = portfolioValue.add(balanceValue);
  });

  return portfolioValue;
};

export function mapToMarket<T extends EntityWithMarket>(entities: T[]): KeyMap<T[]> {
  const res: T[][] = [];

  entities.forEach((entity) => {
    const marketId: string = entity.market.id;
    res[+marketId] = [...(res[+marketId] ?? []), entity];
  });

  for (let i = 0; i < res.length; i += 1) {
    if (res[i] === undefined) res[i] = [];
  }

  return Object.fromEntries(res.map((list, idx) => [idx, list])) as unknown as KeyMap<T[]>;
}

export function mapToToken(entities: TokenBalance[]): KeyMap<TokenBalance[]> {
  const res: TokenBalance[][] = [];

  entities.forEach((entity) => {
    const tokenId: string = entity.token.id;
    res[+tokenId] = [...(res[+tokenId] ?? []), entity];
  });

  for (let i = 0; i < res.length; i += 1) {
    if (res[i] === undefined) res[i] = [];
  }

  return Object.fromEntries(res.map((list, idx) => [idx, list])) as unknown as KeyMap<
    TokenBalance[]
  >;
}
