import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { BaseContainer } from '../Container';
import { WalletButton } from '../WalletButton';
import { NavLink } from './NavLink';
import useLayout from '../../contexts/LayoutContext';

import logoFull from '../../assets/images/logo-full.png';
import logoSmall from '../../assets/images/logo.png';
import { SettingsButton } from '../SettingsButton';

const NavWrapper = styled(BaseContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    justify-content: flex-start;
  }
`;

const Logo = styled.img`
  height: 1.8rem;
  min-height: 100%;
  width: auto;
  padding: 0.7rem 1.25rem;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    border-right: 1px solid ${({ theme }) => theme.bg5};
  }
`;

const LinkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    overflow-x: scroll;

    *:last-child {
      margin-right: 3rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 0.5rem;
  gap: 0.5rem;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    margin-right: 0.5rem;
    margin-left: auto;
  }
`;

const OverflowHint = styled.div`
  background: linear-gradient(90deg, #00000000 0%, ${({ theme }) => theme.bg2} 100%);
  z-index: 2;
  width: 6rem;
  height: 3.705rem;
  margin-left: -3rem;
  pointer-events: none;
`;

export function NavBar() {
  const { isMobile, isTablet } = useLayout();
  const { t } = useTranslation();

  return (
    <NavWrapper>
      <Link to="/trade">
        <Logo src={isMobile || isTablet ? logoSmall : logoFull} />
      </Link>
      <LinkWrapper>
        <NavLink to="/portfolio">{t('Portfolio')}</NavLink>
        <NavLink to="/trade">{t('Trade')}</NavLink>
        <NavLink to="/pools">{t('Pool')}</NavLink>
        {/* <NavLink to="/rankings">{t('Rank')}</NavLink> */}
      </LinkWrapper>
      {isMobile && <OverflowHint />}
      <ButtonWrapper>
        <WalletButton />
        <SettingsButton />
      </ButtonWrapper>
    </NavWrapper>
  );
}
