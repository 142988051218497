import React, { Suspense /* , useEffect */ } from 'react';
import styled from 'styled-components/macro';
import { Routes, Route } from 'react-router-dom';
/* import { toast } from 'react-toastify'; */

import { TradeLogicProvider } from '../contexts/TradeLogicContext';
import { NavBar } from '../components/Navbar';
import { ToastContainer } from '../components/Toast';
import { Loader as BaseLoader } from '../components/Loader';
import { useEnsureTermsSigned } from '../hooks/useEnsureTermsSigned';
/* import { Small1, Small2 } from '../components/Text'; */

const Trade = React.lazy(() => import('./Trade'));
const Pools = React.lazy(() => import('./Pools'));
const PoolDetails = React.lazy(() => import('./PoolDetails'));
const Portfolio = React.lazy(() => import('./Portfolio'));
/* const Rankings = React.lazy(() => import('./Rankings')); */

const AppWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0.2rem 0.25rem;
  min-height: 100vh;
  box-sizing: border-box;
`;

const Loader = styled(BaseLoader)`
  color: ${({ theme }) => theme.gray2};
  background: ${({ theme }) => theme.gray2};
  margin: auto;
  height: 100%;

  &:before,
  :after {
    background: ${({ theme }) => theme.gray2};
  }
`;

/* const LearnLink = styled(Small2)`
 *   color: ${({ theme }) => theme.white};
 *   text-decoration: underline;
 *   font-weight: 700;
 * `;
 *
 * const ToastTitle = styled(Small1)`
 *   font-weight: 700;
 *   margin-bottom: 0.25rem;
 * `; */

export default function App() {
  useEnsureTermsSigned();

  /* useEffect(() => {
   *   toast.info(
   *     <div>
   *       <ToastTitle>Increment on zkSync Era: Genesis Orb</ToastTitle>
   *       <Small2>
   *         Only 100 limited collectibles up for grabs.{' '}
   *         <LearnLink as="a" href="/">
   *           Learn more →
   *         </LearnLink>
   *       </Small2>
   *     </div>,
   *     {
   *       autoClose: 30000,
   *     },
   *   );
   * }, []);
   */
  return (
    <AppWrapper>
      <NavBar />
      <ToastContainer />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={
              <TradeLogicProvider>
                <Trade />
              </TradeLogicProvider>
            }
          />
          <Route
            path="/trade/"
            element={
              <TradeLogicProvider>
                <Trade />
              </TradeLogicProvider>
            }
          />
          <Route
            path="/trade/:id/*"
            element={
              <TradeLogicProvider>
                <Trade />
              </TradeLogicProvider>
            }
          />
          <Route path="/pools" element={<Pools />} />
          <Route path="/pools/:id/*" element={<PoolDetails />} />
          <Route path="/portfolio" element={<Portfolio />} />
          {/* <Route path="/rankings" element={<Rankings />} /> */}
        </Routes>
      </Suspense>
    </AppWrapper>
  );
}
