import {
  DefaultTheme,
  createGlobalStyle,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components/macro';
import 'react-toastify/dist/ReactToastify.css';
import 'inter-ui/inter.css';

export const breakpoints = [600, 800, 1400, 1800];

const customTheme: DefaultTheme = {
  white: '#FFFFFF',
  green: '#5BDE4C',
  orange: '#DE894C',
  red: '#DE554C',
  blue: '#342BCF',

  gold: '#DEBE4C',
  silver: '#F2F3F7',
  bronze: '#E5955C',

  greenAlpha: '#5BDE4C40',
  redAlpha: '#DE554C40',

  lgrad0: 'linear-gradient(90deg, #475ADD 0%, #A86BE1 100%)',
  lgrad1: 'linear-gradient(90deg, #34FF13 28.66%, #FF5A13 73.66%, #FF1313 100%)',
  lgrad2:
    'linear-gradient(90deg, #FF1313 0%, #FF5A13 5%, #34FF13 30%, #34FF13 70%, #FF5A13 95%, #FF1313 100%)',
  agrad0:
    'conic-gradient(from 67.8deg at 50% 50.74%, #A86BE1 0deg, #475ADD 176.25deg, #A86BE1 360deg)',
  agrad1:
    'conic-gradient(from 90deg at 50% 50.91%, #A86BE1 0deg, #7FE0DD 110.95deg, #475ADD 178.44deg, #A86BE1 360deg)',

  hover4: '0rem 0rem 0.25rem rgba(137, 137, 153, 0.5)',

  gray0: 'rgba(57, 59, 70, 0.4)',
  gray1: '#394260',
  gray2: '#505771',
  gray3: '#5C6175',
  gray4: '#8A8A99',
  gray5: '#858DAD',

  bg0: '#07080D',
  bg1: '#0C0F19',
  bg2: '#151823',
  bg3: '#1B1F2D',
  bg4: '#222739',
  bg5: '#2B2F3C',
  bg6: '#323239',

  mobile: `${breakpoints[0]}px`,
  tablet: `${breakpoints[1]}px`,
  desktopMedium: `${breakpoints[2]}px`,
  desktopLarge: `${breakpoints[3]}px`,

  slow: '300ms',
  med: '200ms',
  fast: '100ms',

  dropdownZ: 2,
  tooltipZ: 9,
  navZ: 10,
};

export interface ThemeProviderProps {
  // eslint-disable-next-line
  children: any;
}

export function ThemeProvider({ children }: ThemeProviderProps) {
  return (
    <StyledComponentsThemeProvider theme={customTheme}>{children}</StyledComponentsThemeProvider>
  );
}

export const GlobalStyling = createGlobalStyle`
  :root {
    --toastify-color-dark: ${({ theme }) => theme.bg3};
    --toastify-color-error: ${({ theme }) => theme.red};
    --toastify-font-family: Inter, sans-serif;
    --toastify-text-color-dark: ${({ theme }) => theme.white};
    --toastify-text-color-error: ${({ theme }) => theme.white};
    --toastify-z-index: 99999;

    --onboard-white: ${({ theme }) => theme.bg2};
    --onboard-black: ${({ theme }) => theme.white};

    --onboard-primary-1: ${({ theme }) => theme.bg2};
    --onboard-primary-100: ${({ theme }) => theme.bg3};
    --onboard-primary-200: ${({ theme }) => theme.bg3};
    --onboard-primary-300: ${({ theme }) => theme.bg3};
    --onboard-primary-400: ${({ theme }) => theme.bg3};
    --onboard-primary-500: ${({ theme }) => theme.gray5};
    --onboard-primary-600: ${({ theme }) => theme.bg3};
    --onboard-primary-700: ${({ theme }) => theme.bg3};

    --onboard-gray-100: ${({ theme }) => theme.bg4};
    --onboard-gray-200: ${({ theme }) => theme.white};
    --onboard-gray-300: ${({ theme }) => theme.white};
    --onboard-gray-400: ${({ theme }) => theme.white};
    --onboard-gray-500: ${({ theme }) => theme.gray5};
    --onboard-gray-600: ${({ theme }) => theme.white};
    --onboard-gray-700: ${({ theme }) => theme.white};

    --onboard-success-100: ${({ theme }) => theme.green}44;
    --onboard-success-200: ${({ theme }) => theme.green};
    --onboard-success-300: ${({ theme }) => theme.white};
    --onboard-success-400: ${({ theme }) => theme.white};
    --onboard-success-500: ${({ theme }) => theme.white};
    --onboard-success-600: rgba(0,0,0,0);
    --onboard-success-700: ${({ theme }) => theme.green};

    --onboard-spacing-1: 3rem;
    --onboard-spacing-2: 2rem;
    --onboard-spacing-3: 1.5rem;
    --onboard-spacing-4: 1.5rem;
    --onboard-spacing-5: 1rem;

    --onboard-connect-sidebar-background: ${({ theme }) => theme.bg2};
    --onboard-connect-header-background: ${({ theme }) => theme.bg3};
    --onboard-connect-header-color: ${({ theme }) => theme.white};

    --onboard-link-color:  ${({ theme }) => theme.white};

    --onboard-close-button-background: ${({ theme }) => theme.bg3};
    --onboard-close-button-color: ${({ theme }) => theme.gray5};

    --onboard-warning-100: ${({ theme }) => theme.red}43;
    --onboard-warning-400: #00000000;
    --onboard-warning-500: ${({ theme }) => theme.red};

    --onboard-wallet-button-background: ${({ theme }) => theme.bg4};
    --onboard-wallet-button-background-hover: ${({ theme }) => theme.bg3};
    --onboard-wallet-button-color: ${({ theme }) => theme.white};
    --onboard-wallet-button-border-color: rgba(0,0,0,0);
    --onboard-wallet-app-icon-border-color: rgba(0,0,0,0);
    --onboard-wallet-columns: 1;

    --onboard-modal-border-radius: 0.675rem;
    --onboard-modal-background: ${({ theme }) => theme.bg2};

    --onboard-font-family-normal: 'Inter';
    --onboard-font-family-semibold: 'Inter';
    --onboard-font-family-light: 'Inter';
  }

  html {
    color: ${({ theme }) => theme.white};
    background: ${({ theme }) => theme.bg1};
    font-family: "Inter", "system-ui", sans-serif;

    @media only screen and (min-width: ${({ theme }) => theme.tablet}) {
      overflow:hidden;
    }
  }

  @supports(font-variation-settings: normal) {
    html {
      font-family: "Inter var", "system-ui", sans-serif;
      font-feature-settings: 'ss01', 'tnum';
    }
  }

  body {
    margin: 0;
  }

  * {
    ms-overflow-stype: none;
    scrollbar-width: none;

    &:focus {
      outline: none;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media only screen and (min-width: ${({ theme }) => theme.desktopLarge}) {
    html {
      font-size: 100%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.desktopLarge}) {
    html {
      font-size: 90%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.desktopMedium}) {
    html {
      font-size: 90%;
    }
  }

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    html {
      font-size: 90%;
    }
  }
`;
