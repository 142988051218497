import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import useLayout from '../../contexts/LayoutContext';
import { ButtonM } from '../Button';
import { supportedLanguages } from '../../locales/i18n';
import { Label2, Nav2 } from '../Text';
import { ReactComponent as ArrowBase } from '../../assets/vectors/arrow-s.svg';
import ellipsis from '../../assets/vectors/ellipsis.svg';
import hamburger from '../../assets/vectors/hamburger.svg';

const Container = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.navZ};
`;

const ButtonWrapper = styled(ButtonM)<{
  expanded: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${({ expanded, theme }) => (expanded ? theme.bg4 : 'none')};
  border: 2px solid ${({ expanded, theme }) => (expanded ? theme.bg4 : theme.gray1)};
  padding: 0 0.625rem;
  height: 2.625rem;

  &:hover {
    filter: drop-shadow(0rem 0rem 0.25rem ${({ theme }) => theme.gray1});
  }

  &:enabled:active {
    filter: none;
    background: ${({ theme }) => theme.gray1};
  }
`;

const DropdownOption = styled(Nav2)<{ selected?: boolean }>`
  white-space: nowrap;
  color: ${({ selected, theme }) => (selected ? theme.white : theme.gray4)};
  text-decoration: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 0.5rem;
    font-weight: 400;
  }

  &:hover {
    color: white;

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }

  path {
    stroke: ${({ theme }) => theme.gray4};
  }
`;

const LanguageOption = styled(DropdownOption)`
  margin-left: 0.25rem;
`;

const Ellipsis = styled.img`
  width: 1rem;
  height: 1rem;
`;

const Arrow = styled(ArrowBase)`
  transform: rotate(270deg);
  width: 0.75rem;
  height: 0.75rem;
`;

const DropdownContainer = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.navZ};
  top: 100%;
  margin-top: 0.45rem;
  right: 0;
  background: ${({ theme }) => theme.bg4};
  box-sizing: border-box;
  width: auto;
  min-width: 10rem;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
  gap: 0.625rem;
`;

const Back = styled(Label2)`
  color: ${({ theme }) => theme.gray4};
  margin-bottom: 0;
  cursor: pointer;
  font-size: 0.75rem;

  &:hover {
    color: ${({ theme }) => theme.white};
  }
`;

export function SettingsButton() {
  const { t, i18n } = useTranslation();
  const { isMobile } = useLayout();

  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);
  const [languageSelect, setLanguageSelect] = useState(false);

  const close = () => {
    setFocused(false);
    setExpanded(false);
    setLanguageSelect(false);
  };

  useEffect(() => {
    if (focused) {
      setExpanded(true);
    }
  }, [focused]);

  const handleToggleClick = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Container>
      <ButtonWrapper onClick={handleToggleClick} expanded={expanded}>
        <Ellipsis src={isMobile ? hamburger : ellipsis} />
      </ButtonWrapper>
      {expanded && (
        <DropdownContainer onMouseLeave={close}>
          {languageSelect ? (
            <>
              <Back as="div" onClick={() => setLanguageSelect(false)}>
                &lt;- {t('Back')}
              </Back>
              {Object.keys(supportedLanguages).map((lang) => (
                <LanguageOption
                  selected={i18n.language === lang}
                  onClick={() => {
                    i18n.changeLanguage(lang);
                  }}
                  key={lang}
                >
                  {supportedLanguages[lang]}
                </LanguageOption>
              ))}
            </>
          ) : (
            <>
              <DropdownOption
                as="a"
                href="https://docs.increment.finance/increment/protocol/user-guide"
                target="_blank"
              >
                {t('How-To Guides')}
              </DropdownOption>
              <DropdownOption as="a" href="https://discord.increment.finance/" target="_blank">
                {t('Ask Questions')}
              </DropdownOption>
              <DropdownOption
                as="a"
                href="https://docs.increment.finance/developer-docs/"
                target="_blank"
              >
                {t('Developer Docs')}
              </DropdownOption>
              <DropdownOption
                onClick={() => {
                  setLanguageSelect(true);
                }}
              >
                {t('Language')}: <span> {supportedLanguages[i18n.language]}</span>
                <Arrow />
              </DropdownOption>
            </>
          )}
        </DropdownContainer>
      )}
    </Container>
  );
}
