export interface Network {
  chainId: string;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  contracts: {
    clearingHouse: string;
    clearingHouseViewer: string;
    vault: string;
    curveCryptoViews: string;
    multicall3: string;
    ua: string;
    uaHelper: string;
  };
}

export interface NetworkList {
  [key: string]: Network;
}

export const targetChainId = process.env.REACT_APP_TARGET_CHAIN_ID || '0x12c';

export const supportedNetworks: NetworkList = {
  // 5: {
  //   chainId: '0x5',
  //   chainName: 'Goerli',
  //   nativeCurrency: {
  //     name: 'Goerli Eth',
  //     symbol: 'ETH',
  //     decimals: 18,
  //   },
  //   rpcUrls: [`https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`],
  //   blockExplorerUrls: ['https://goerli.etherscan.io/tx', 'https://goerli.etherscan.io/address/'],
  //   contracts: {
  //     clearingHouse: '0x215A290743Da5Fcdd2745B63e7038407C1fCeA17',
  //     clearingHouseViewer: '0xFd581492b91E5C93fF16B6f9C829E534579a6808',
  //     vault: '0x802d62ccd22fbC85394144b2303B2c01171A935B',
  //     curveCryptoViews: '0x307cE335Cfc681a72A8b331636F960bD7aC49A38',
  //   },
  // },
  300: {
    chainId: '0x12c',
    chainName: 'zkSync era sepolia',
    nativeCurrency: {
      name: 'zkSync Eth',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://sepolia.era.zksync.dev'],
    blockExplorerUrls: [
      'https://explorer.sepolia.era.zksync.dev/transactions/',
      'https://explorer.sepolia.era.zksync.dev/address/',
    ],
    contracts: {
      clearingHouse: '0x6C3388fc1dfa9733FeED87cD3639b463Ee072a8a',
      clearingHouseViewer: '0x8888F364774440166B0143910249a2D762766759',
      vault: '0x8D3dc31BBbd43a27bE0224db6F5bd7780a081d84',
      curveCryptoViews: '0x979864867e22f5467259f03A16822759376D8e98',
      multicall3: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
      ua: '0x76e3E4B8E7ad3e072218501269d4c65AE080dC88',
      uaHelper: '0x9bE87B9f0a93933e059e67B3B1d61391EB73F6fB',
    },
  },
};
