import { useState, useMemo, useEffect, createContext, useContext } from 'react';

import { useMarketsQuery } from '../graphql/generated';
import { marketList as MARKET_DATA } from '../data/markets';
import { useDeepRefresh } from '../hooks/useDeepRefresh';
import { GenericCandle } from '../hooks/types';

export interface GlobalPosition {
  timestamp: string;
  totalQuoteProvided: string;
  totalBaseProvided: string;
  totalLiquidityProvided: string;
  totalTradingFeesGrowth: string;
  totalBaseFeesGrowth: string;
  totalQuoteFeesGrowth: string;
  cumFundingPerLpToken: string;
  traderLongs: string;
  traderShorts: string;
}

export interface Market {
  id: string;
  displayDecimals: number;
  baseDecimals: number;
  name: string;
  base: string;
  quote: string;
  riskWeight: string;
  maxLiquidityProvided: string;
  outFee: string;
  baseSupply: string;
  quoteSupply: string;
  symbol: string;
  price: string;
  img: string;
  assetClass: string;
  insuranceFee: string;
  cryptoSwapPool: string;
  minMargin: string;
  minMarginAtCreation: string;
  markPrice: string;
  indexPrice: string;
  cumFundingRate: string;
  fundingRate: string;
  high: string;
  low: string;
  last: string;
  volCandles: GenericCandle[];
  latestGlobalPosition?: GlobalPosition;
  lastWeeksGlobalPosition?: GlobalPosition;
  yesterdaysGlobalPosition?: GlobalPosition;
}

interface MarketsContextType {
  marketList: Market[];
  refreshMarketInfo: () => void;
  selectedMarketId?: string;
  setSelectedMarketId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  selectedMarket?: Market;
}

const MarketsContext = createContext<MarketsContextType>({
  marketList: [],
  refreshMarketInfo: () => null,
});

export function MarketsProvider({ children }: { children: React.ReactNode }) {
  const [marketList, setMarketList] = useState<Market[]>([]);
  const [selectedMarketId, setSelectedMarketId] = useState<string>();
  const [marketsQuery, invalidateQuery] = useMarketsQuery({
    variables: {
      yesterday: Math.round(Date.now().valueOf() / 1000) - 24 * 60 * 60, // 24h ago
      lastWeek: Math.round(Date.now().valueOf() / 1000) - 24 * 60 * 60 * 7, // 1 Week ago
    },
  });

  const [marketInfoData, refreshMarketInfo] = useDeepRefresh({
    queryData: marketsQuery.data,
    fetching: marketsQuery.fetching,
    invalidateQuery,
    id: 'markets',
  });

  const selectedMarket = useMemo<Market | undefined>(() => {
    if (marketList && selectedMarketId) {
      return marketList.find((market) => market.id === selectedMarketId);
    }
    return undefined;
  }, [marketList, selectedMarketId]);

  useEffect(() => {
    if (marketInfoData?.markets) {
      const list: Market[] = marketInfoData.markets
        .filter(({ id }) => !!MARKET_DATA[Number(id)])
        .map(
          ({
            id,
            latestPrice,
            insuranceFee,
            minMargin,
            minMarginAtCreation,
            riskWeight,
            maxLiquidityProvided,
            high,
            low,
            last,
            cryptoSwapPool,
            latestGlobalPosition,
            outFee,
            lastWeeksGlobalPosition,
            yesterdaysGlobalPosition,
            volCandles,
          }) => ({
            id,
            displayDecimals: MARKET_DATA[Number(id)].displayDecimals,
            baseDecimals: MARKET_DATA[Number(id)].baseDecimals,
            name: MARKET_DATA[Number(id)].label,
            base: MARKET_DATA[Number(id)].base,
            quote: MARKET_DATA[Number(id)].quote,
            symbol: MARKET_DATA[Number(id)].id,
            img: MARKET_DATA[Number(id)].img,
            assetClass: MARKET_DATA[Number(id)].assetClass,
            insuranceFee,
            minMargin,
            minMarginAtCreation,
            cryptoSwapPool,
            riskWeight,
            maxLiquidityProvided,
            outFee,
            quoteSupply: latestPrice.quoteSupply,
            baseSupply: latestPrice.baseSupply,
            price: latestPrice.value,
            markPrice: latestPrice.mark,
            indexPrice: latestPrice.index,
            fundingRate: latestPrice.fundingRate,
            cumFundingRate: latestPrice.cumFundingRate,
            high: high[0]?.value || latestPrice.value,
            low: low[0]?.value || latestPrice.value,
            last: last[0]?.value || latestPrice.value,
            volCandles: volCandles as GenericCandle[],
            latestGlobalPosition: latestGlobalPosition?.[0]
              ? (latestGlobalPosition[0] as GlobalPosition)
              : undefined,
            lastWeeksGlobalPosition: lastWeeksGlobalPosition?.[0]
              ? (lastWeeksGlobalPosition[0] as GlobalPosition)
              : undefined,
            yesterdaysGlobalPosition: yesterdaysGlobalPosition?.[0]
              ? (yesterdaysGlobalPosition[0] as GlobalPosition)
              : undefined,
          }),
        );
      setMarketList(list);
    }
  }, [marketInfoData, marketsQuery]);

  const marketsValue = useMemo(
    () => ({
      marketList,
      selectedMarketId,
      setSelectedMarketId,
      selectedMarket,
      refreshMarketInfo,
    }),
    [marketList, selectedMarketId, setSelectedMarketId, selectedMarket, refreshMarketInfo],
  );

  return <MarketsContext.Provider value={marketsValue}>{children}</MarketsContext.Provider>;
}
const useMarkets = () => useContext(MarketsContext) as MarketsContextType;

export default useMarkets;
