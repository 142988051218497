import { useState, useCallback, useMemo, createContext, useContext, ReactNode } from 'react';

import { Modal } from '../components/Modal';

export interface ModalInfo {
  title: string;
  content: ReactNode;
}

interface ModalContextType {
  setModal: (modalInfo: ModalInfo) => void;
  closeModal: () => void;
  modalContent: ReactNode | null;
}

const ModalContext = createContext<ModalContextType>({
  setModal: (modalInfo: ModalInfo) => modalInfo,
  closeModal: () => null,
  modalContent: null,
});

export function ModalProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState<ReactNode>(null);

  const setModal = useCallback(
    (modalInfo: { title: string; content: ReactNode }) => {
      setTitle(modalInfo.title);
      setContent(modalInfo.content);
      setIsOpen(true);
    },
    [setTitle, setContent, setIsOpen],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setTitle('');
    setContent(null);
  }, [setIsOpen]);

  const modalValues = useMemo(
    () => ({
      setModal,
      closeModal,
      modalContent: content,
    }),
    [setModal, closeModal, content],
  );

  return (
    <ModalContext.Provider value={modalValues}>
      {children}
      {content && <Modal isOpen={isOpen} title={title} content={content} closeModal={closeModal} />}
    </ModalContext.Provider>
  );
}
const useModal = () => useContext(ModalContext) as ModalContextType;

export default useModal;
